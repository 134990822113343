import { createI18n, useI18n } from 'vue-i18n'
// Auth
import SignIn from './auth/SignIn.json' 
// Landing
import SubscribeNewsletter from './landing/SubscribeNewsletter.json' 
// HOME PAGE
import header     from './header.json'
import motto      from './motto.json'
import space      from './space.json'
// import contacts   from './contacts.json'
import footer     from './footer.json'
// GALLERY
import gallery    from './gallery.json'
// COMMUNITY
import community  from './community.json'
import comments   from './comments.json'

const supportedLocales = {
  en: "🌎 EN",
  ru: "🇷🇺 RU",
  zh: "🇨🇳 ZH",
  fr: "🇫🇷 FR",
  de: "🇩🇪 DE",
  it: "🇮🇹 IT"
}

// Объединяем все JSON модули в один объект
const messages = {}

// добавляем содержимое каждого языкового словаря в messages
for (const locale of Object.keys(supportedLocales)) {
  messages[locale] = {
    ...((header[locale] || {})),
    ...((SignIn[locale] || {})),
    ...((motto[locale] || {})),
    ...((space[locale] || {})),
    ...((footer[locale] || {})),
    // LANDING
    ...((SubscribeNewsletter[locale] || {})),
    // GALLERY
    ...((gallery[locale] || {})),
    // COMMUNITY
    ...((community[locale] || {})),
    ...((comments[locale] || {}))
  }
}

const i18n = createI18n({
  locale: 'en',
  fallbackLocale: "en-default",
  legacy: false,
  globalInjection: true,
  messages: messages,
  availableLocales: Object.keys(supportedLocales)
})

export default i18n;
