<template>
  <ul class="t-semi gap-small flex-nowrap flex-center flex">
    <li>
      <router-link  
        to="/events"
        class="font-second uppercase"
     >
        {{ t('HEADER.MENU.EVENTS') }}
      </router-link>
    </li>
   
    <li>
      <router-link  
        to="/radio"
        class="font-second uppercase"
     >
        {{ t('HEADER.MENU.RADIO') }}
      </router-link>
    </li>
   

    <li>
      <router-link  
        to="/gallery"
        class="font-second uppercase"
     >
        {{ t('HEADER.MENU.GALLERY') }}
      </router-link>
    </li>
    

    <li>
      <router-link  
        to="/community" 
        class="font-second uppercase"
     >
        {{ t('HEADER.MENU.BLOG') }}
      </router-link>
    </li>
    <!-- <li>
      <router-link  
        to="/governance" 
        class="font-second"
     >
        {{ t('HEADER.MENU.GOVERNANCE') }}
      </router-link>
    </li> -->
  </ul>
</template>


<script setup="props">
/////////////////////////////
// COMPONENT DEPENDENCIES
/////////////////////////////
import { computed, } from 'vue';
import { useRoute,useRouter } from 'vue-router'
import { useI18n } from 'vue-i18n'

/////////////////////////////
// HELPERS
/////////////////////////////

/////////////////////////////
// CREATED
/////////////////////////////
const route = useRoute()
// Compoted Vars
const text = {
  messages: {
    en: {
      HEADER: {
        MENU: {
          HOME: 'Home',
          MARKETPLACE: 'Marketplace',
          DISPENSARIES: 'Spots',
          GALLERY: 'Gallery',
          EVENTS: 'Events',
          RADIO: 'Radio',
          BLOG: "Community",
          GOVERNANCE: "Governance",
        }
      }
    },
    ru: {
      HEADER: {
        MENU: {
          HOME: 'Главная',
          MARKETPLACE: 'Маркетплейс',
          DISPENSARIES: 'Места',
          GALLERY: 'Галерея',
          RADIO: 'Радио',
          EVENTS: 'События',
          BLOG: "Сообщество",
          GOVERNANCE: "Управление",
        }
      }
    }
  }
}
// Methods
const { t } = useI18n(text)
/////////////////////////////
// MOUNTED
/////////////////////////////
</script>

<style lang="scss" scoped>

.router-link-active, .router-link-exact-active {
  color: rgb(var(--second)) !important;
}

a:hover   { cursor: pointer; color: rgb(var(--second));;} 

</style>
