<template>
  <section 
    @click="radioStore.toggleFullPlayer()"
    class="o-hidden pos-relative pd-thin bg-black t-white flex-v-center flex-nowrap flex gap-medium"
  >
    <!-- {{radioStore.state.pauseCount}}
    {{radioStore.state.playCount}}
    {{radioStore.state.context}}
    {{radioStore.state.context?.state}} -->
    <div 
      @click.stop="radioStore.state.isPlaying ? radioStore.pause() : radioStore.play()" 
      class="flex-center flex i-big radius-extra"
      :class="{
        'bg-black br-solid br-white-transp-20 br-1px': !radioStore.state.isPlaying,
        'bg-white-transp-20': radioStore.state.isPlaying
      }" 
    >
      <button 
        ref="player_button" 
        :class="{
          'paused': !radioStore.state.isPlaying
        }" 
        class="i-regular pauseplay-btn"
      >
        <div class="radius-small d1"></div>
        <div class="radius-small d2"></div>
      </button>
    </div>

    <div 
      class="mn-l-auto mn-r-auto t-center"
    >
        <p 
          class="p-medium"
        >
          {{ radioStore.state.whatPlaying?.title }}
        </p>
        <p 
          class="p-small t-transp"
        >
          {{ radioStore.state.whatPlaying?.artist }}
        </p>
    </div>
    <div class="i-big pos-relative">
      
      <!-- Custom audio player controls -->
      <transition name="scaleTransition-5px"  mode="out-in">
        <div
           v-if="radioStore.state.isPlaying || options.position !== 'bottom-navigation'"
        >

          <img 
            v-if="radioStore.state.whatPlaying?.artwork"
            :src="radioStore.state.whatPlaying?.artwork" 
            :class="{ 'rotating': radioStore.state.isPlaying }" 
            class="i-big radius-extra br-solid br-1px br-white-transp-20"
          >
        </div>

        <div 
         v-else-if="!radioStore.state.isPlaying && options.position === 'bottom-navigation'"
          class="flex-center flex i-big radius-extra"
          @click.stop="radioStore.hidePlayer()"
        >
          <svg 
            class="i-regular"
            width="26"
            height="26" 
            viewBox="0 0 26 26" 
            fill="none" 
            xmlns="http://www.w3.org/2000/svg"
          >
            <rect 
              width="24"
              height="24" 
              transform="translate(1 1)" 
            />
            <path 
              fill="rgb(var(--white))"
              fill-rule="evenodd" 
              clip-rule="evenodd" 
              d="M12.9996 10.8L22.8994 0.900244L25.0993 3.1002L15.1995 13L25.0993 22.8998L22.8994 25.0998L12.9996 15.2L3.09976 25.0998L0.899801 22.8998L10.7996 13L0.899801 3.1002L3.09976 0.900244L12.9996 10.8Z"/>
          </svg>

        </div>
      </transition>
    </div>

  </section>
</template>

<script setup>
import { ref, onMounted, onUnmounted, watch } from 'vue';
import { App } from '@capacitor/app';

import * as radioStore from './radio.store.js';

const props = defineProps({
  options: {
    type: Object,
    default: {
      position: false
    }
  }
})

let isAppActive = true

App.addListener('appStateChange', ({ isActive }) => {
  isAppActive = isActive;

  let emptiedEventFired = false;
  let playEventFired = false;
  let playingEventFired = false;

  if (radioStore.state.player) {

    radioStore.state.player.addEventListener("emptied", (event) => {
      // alert('test')
      emptiedEventFired = true;
    });
    radioStore.state.player.addEventListener("play", (event) => {
       // alert('test')
      playEventFired = true;
      if (!emptiedEventFired) {
        radioStore.pause();
      }
    });
  
  }

});


onMounted(async() => {
  radioStore.initWebSocket();

  navigator.mediaSession.setActionHandler('pause', await radioStore.pause);
  navigator.mediaSession.setActionHandler('play', await radioStore.play);
  navigator.mediaSession.setActionHandler('stop', await radioStore.pause);
});
</script>

<style lang="scss">
.pauseplay-btn {
  --bars-anim-duration: 300ms;
  --clip-anim-duration: 150ms;

  position: relative;
  background-color: transparent;
  border: none;
  outline: none;
  -webkit-tap-highlight-color: transparent;
  transition: clip-path ease-out var(--clip-anim-duration);
  clip-path: polygon(0 0, 0 100%, 100% 100%, 100% 0);
}

.pauseplay-btn.paused {
  clip-path: polygon(0 0, 0 100%, 100% 50%, 100% 50%);
}

.pauseplay-btn div {
  position: absolute;
  top: 0;
  width: 30%;
  height: 100%;
  background: white;
  transition: transform ease-out var(--bars-anim-duration);
}

.pauseplay-btn.paused div {
  transform: scaleX(2);
}

.pauseplay-btn div.d1 {
  left: 0;
  transform-origin: left;
}

.pauseplay-btn div.d2 {
  right: 0;
  transform-origin: right;
}

@keyframes rotate {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.rotating {
  animation: rotate 16s linear infinite;
}

keyframes slow-rotate {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.rotating-slow {
  animation: rotate 4s linear infinite;
}

@property --a {
  syntax: '<angle>';
  inherits: false;
  initial-value: 0deg;
}

@property --b {
  syntax: '<angle>';
  inherits: false;
  initial-value: 0deg;
}
.rotate-gradient {
  --a: 0deg; /* needed for firefox to have a valid output */
  --b: 0deg; /* needed for firefox to have a valid output */
  transition: --a 0.5s, --b 0.5s; /* Corrected the transition syntax */

  background:
    conic-gradient(from var(--a), rgba(255, 255, 255, 0.1), rgba(0, 0, 0, 0.33), rgba(255, 255, 255, 0.1)),
    linear-gradient(180deg, rgba(0, 0, 0, 1) 0%, rgba(255, 255, 255, 0.1) 50%, rgba(0, 0, 0, 1) 100%), 
    linear-gradient(180deg, #000 0%, rgba(10, 10, 10, 0.00) 100%), 
    #000;
}

.rotate-gradient-anim {
   animation: rotate_gradient 7s linear infinite;
}

@keyframes rotate_gradient {
  0% {
    --a: 0deg;
    --b: 0deg;
  }
  100% {
    --a: 360deg;
    --b: 720deg;
  }
}

</style>