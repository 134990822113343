import { createSSRApp, createApp } from 'vue';
// Router
import { _createRouter } from './router';
// Meta
import { createHead } from '@unhead/vue'
// Store
import store from './store';
// Mixins
import { globalStore, mixins, layoutApp, appRenderer } from '@ozdao/prometheus-framework/modules/globals/client';
// Localization i18n
import i18n from "@/localization";
// Modules
import globals from '@ozdao/prometheus-framework/modules/globals/client';
import auth from '@ozdao/prometheus-framework/modules/auth/client';
import orders from '@ozdao/prometheus-framework/modules/orders/client';
import spots from '@ozdao/prometheus-framework/modules/spots/client';
import pages from '@ozdao/prometheus-framework/modules/pages/client';
import events from '@ozdao/prometheus-framework/modules/events/client';

import '@/scss/theme.scss'
// //////////////////////////////////////////////////////
// App Instance Creation
// //////////////////////////////////////////////////////
export function _createApp() {
  let options = {
    messages: {
      "en":{
        meta: {
          "title":"NMS - Music & Friends",
          "description":"NMS is your go-to beach vibe digital companion for the world of parties, events, DJ beats, new connections, and eye-catching photos.",
          "keywords":"Bar, Terrace, Phuket, Karon Beach, Cocktails, Dining, Sea View, Parties, Events, Chill-Out Zone",
          "og":{
              "title":"NMS - Music & Friends",
              "description":"NMS is your go-to beach vibe digital companion for the world of parties, events, DJ beats, new connections, and eye-catching photos.",
              "image":"/favicon/cover.jpg"
          },
          "twitter":{
              "title":"NMS - Music & Friends",
              "description":"NMS is your go-to beach vibe digital companion for the world of parties, events, DJ beats, new connections, and eye-catching photos.",
              "image":"/favicon/cover.jpg",
              "card":"summary_large_image"
          },
          "htmlAttrs":{
              "lang":"en"
          }
        }
      },
      "ru":{
        meta: {
          "title":"NMS - Музыка & Друзья",
          "description":"NMS — ваш цифровой спутник на пляже в мире вечеринок, мероприятий, диджеев, новых связей и привлекательных фотографий.",
          "keywords":"Бар, Терраса, Пхукет, Пляж Карон, Коктейли, Ресторан, Вид на море, Вечеринки, События, Зона отдыха",
          "og":{
              "title":"NMS - Музыка & Друзья",
              "description":"NMS — ваш цифровой спутник на пляже в мире вечеринок, мероприятий, диджеев, новых связей и привлекательных фотографий. Наслаждайтесь захватывающим видом на море, попивая вкусные коктейли и наслаждаясь изысканными блюдами из нашего меню.",
              "image":"/favicon/cover.jpg"
          },
          "twitter":{
              "title":"NMS - Музыка & Друзья",
              "description":"NMS — ваш цифровой спутник на пляже в мире вечеринок, мероприятий, диджеев, новых связей и привлекательных фотографий.",
              "image":"/favicon/cover.jpg",
              "card":"summary_large_image"
          },
          "htmlAttrs":{
              "lang":"ru"
          }
        }
      }
    }
  }
  const app = process.env.MOBILE_APP ? createApp(layoutApp, options) : createSSRApp(layoutApp, options);

  const router = _createRouter(); 
  const meta = createHead()
  
  globals.initialize(app, store, router)
  auth.initialize(app, store, router)
  orders.initialize(app, store, router)
  spots.initialize(app, store, router)
  pages.initialize(app, store, router)
  events.initialize(app, store, router);

  app
    .use(router)
    .use(i18n)
    .use(meta)

  if (process.env.MOBILE_APP)  { 
    app.mount("#app");
  } else {
    return { 
      app, 
      router, 
      store, 
      i18n,
      meta 
    }
  }
};

// FOR SPA
if (typeof window !== 'undefined' && process.env.MOBILE_APP) {
  _createApp();
}

// FOR SSR / CLIENT ENTRY
if (typeof window !== 'undefined' && !process.env.MOBILE_APP) {
  appRenderer.renderAndMountApp({ createApp: _createApp });
}

// FOR SSR / SERVER ENTRY
export async function render({ url, cookies, languages }) {
  return appRenderer.render({
    url,
    cookies,
    createApp: _createApp
  });
}